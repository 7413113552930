import React from 'react';
import type { IconProps } from '../types';

export const IconColorLUT = (props: IconProps) => (
  <svg
    width="28px"
    height="28px"
    viewBox="0 0 28 28"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Production"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Artboard"
        transform="translate(-58, -175)"
      >
        <g
          id="icon-color-lut"
          transform="translate(58, 175)"
        >
          <rect
            id="Rectangle"
            x="0"
            y="0"
            width="28"
            height="28"
          ></rect>
          <g
            id="Group-3"
            transform="translate(7, 6)"
          >
            <polygon
              id="Path-4"
              fill="#3378C8"
              points="5.3653608 15.2885085 12.1735461 7.20931345 13.2130397 11.248911 11.2982009 14.5315772 7.60883934 15.7670603"
            ></polygon>
            <polygon
              id="Path-3"
              fill="#21DC55"
              points="2.90861351 14.1910201 10.8161464 4.93194681 12.0148357 7.26808935 5.07756073 15.5719157"
            ></polygon>
            <polygon
              id="Path-2"
              fill="#E6FF00"
              points="1.11114723 11.6564599 8.96449984 2.52781588 9.14436752 2.35441261 10.65568 4.86937719 2.6199892 14.3240977"
            ></polygon>
            <path
              d="M0.654581374,10.5573035 L1.07159836,7.1163035 L3.45887024,3.82405096 L6.90861351,0 L8.67637665,2.14325052 C3.73187398,8.46470975 1.14061179,11.7502697 0.902590102,11.9999304 C0.664568411,12.2495912 0.581898835,11.7687155 0.654581374,10.5573035 Z"
              id="Path-5"
              fill="#E60000"
            ></path>
            <g
              id="Group"
              transform="translate(6.6939, 9.3583) rotate(40) translate(-6.6939, -9.3583)translate(3.4103, 2.8577)"
              stroke="#041C4A"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.25"
            >
              <line
                x1="0.5"
                y1="11.5"
                x2="0.5"
                y2="2.19671376e-13"
                id="Path"
              ></line>
              <line
                x1="3.420153"
                y1="0.250185845"
                x2="3.420153"
                y2="13.0011428"
                id="Path"
              ></line>
              <line
                x1="6.06728889"
                y1="0.867657175"
                x2="6.06728889"
                y2="11.7034832"
                id="Path"
              ></line>
            </g>
            <path
              d="M7.52683573,0.255234787 C7.36570938,0.0922486197 7.14226748,0 6.90861351,0 C6.67495954,0 6.45151764,0.0922486197 6.29039129,0.255234787 C5.12761351,1.43158261 0.408613509,6.42775653 0.408613509,10.086887 C0.408613509,13.5449739 3.31844684,15.9999304 6.90861351,15.9999304 C10.4987802,15.9999304 13.4086135,13.5449739 13.4086135,10.086887 C13.4086135,6.42775653 8.68961351,1.43158261 7.52683573,0.255234787 Z"
              id="Path"
              stroke="#E1E1E1"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default IconColorLUT;
