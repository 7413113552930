import React from 'react';
import type { IconProps } from '../types';

export const InvestigationalUse = (props: IconProps) => (
  <svg
    width="68px"
    height="68px"
    viewBox="0 0 68 68"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      id="Investigational-Use"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Investigational-use-messaging-component"
        transform="translate(-55, -284)"
      >
        <g
          id="Messaging-component"
          transform="translate(38.75, 203)"
        >
          <g
            id="Group-19"
            transform="translate(1.25, 72)"
          >
            <g
              id="illustration-investigational-use"
              transform="translate(15, 9)"
            >
              <rect
                id="Rectangle"
                fill="#090C29"
                opacity="0.900000036"
                x="0"
                y="0"
                width="68"
                height="68"
                rx="34"
              ></rect>
              <g
                id="single-neutral-search"
                transform="translate(15, 16)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              >
                <circle
                  id="Oval"
                  stroke="#3A3F99"
                  cx="16.0737705"
                  cy="16.0737705"
                  r="16.0737705"
                ></circle>
                <line
                  x1="37"
                  y1="37"
                  x2="27.295082"
                  y2="27.295082"
                  id="Path"
                  stroke="#3A3F99"
                ></line>
                <path
                  d="M24.8688525,24.8688525 C24.8688525,24.8688525 21.6386587,23.455813 19.3807213,22.6273157 C17.8999493,22.0823767 18.1110313,19.6063894 18.968152,18.6765081 C20.4710303,17.2173856 21.2133915,15.1548596 20.9814262,13.0829629 C21.1101279,11.7283944 20.6465988,10.3841411 19.7082359,9.39065905 C18.7698729,8.39717696 17.4466768,7.84975339 16.0737705,7.88703309 C14.7011699,7.84980444 13.3782601,8.39701666 12.4399544,9.39013526 C11.5016487,10.3832539 11.0379007,11.7270704 11.1661148,13.0813788 C10.9341495,15.1532755 11.6765106,17.2158015 13.179389,18.6749239 C14.0365097,19.6048053 14.2475917,22.0807926 12.7668197,22.6257315 C10.5088823,23.4542289 7.27868852,24.8688525 7.27868852,24.8688525"
                  id="Path"
                  stroke="#348CFD"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default InvestigationalUse;
