import React from 'react';
import type { IconProps } from '../types';

export const ActionNewDialog = (props: IconProps) => (
  <svg
    width="18px"
    height="19px"
    viewBox="0 0 18 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      id="*****Volume-Render-Dialog"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="volume-rendering-dialog"
        transform="translate(-696, -178)"
      >
        <g
          id="action-new-dialog"
          transform="translate(696, 178.5)"
        >
          <rect
            id="Rectangle"
            x="0"
            y="0"
            width="18"
            height="18"
          ></rect>
          <path
            d="M6.73167982,5 L6.73167982,6 L3.23167982,6 C2.40325269,6 1.73167982,6.67157288 1.73167982,7.5 L1.73167982,14.5 C1.73167982,15.3284271 2.40325269,16 3.23167982,16 L10.2316798,16 C11.0601069,16 11.7316798,15.3284271 11.7316798,14.5 L11.7316798,11.5 L12.7316798,11.5 L12.7316798,14.5 C12.7316798,15.8807119 11.6123917,17 10.2316798,17 L3.23167982,17 C1.85096795,17 0.73167982,15.8807119 0.73167982,14.5 L0.73167982,7.5 C0.73167982,6.11928813 1.85096795,5 3.23167982,5 L6.73167982,5 Z"
            id="Path"
            fill="currentColor"
            fillRule="nonzero"
          ></path>
          <line
            x1="8.23167982"
            y1="9.5"
            x2="16.2316798"
            y2="1.5"
            id="Path"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></line>
          <polyline
            id="Path"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            points="11.2316798 1.5 16.2316798 1.5 16.2316798 6.5"
          ></polyline>
        </g>
      </g>
    </g>
  </svg>
);

export default ActionNewDialog;
