import React from 'react';
import type { IconProps } from '../types';

export const Link = (props: IconProps) => (
  <svg
    width="28px"
    height="28px"
    viewBox="0 0 28 28"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      id="tool-stack-image-sync"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <rect
        id="Rectangle"
        x="0"
        y="0"
        width="28"
        height="28"
      ></rect>
      <path
        d="M16.4346891,14.4875228 L16.5216447,14.5744783 C17.2833202,15.336115 18.5181991,15.336115 19.2798746,14.5744783 L23.1459183,10.7049564 C24.2824086,9.55997894 24.2824086,7.71258895 23.1459183,6.56761146 L21.4311549,4.85371762 C20.2872588,3.71542746 18.4385755,3.71542746 17.2946795,4.85371762 L13.4277663,8.71976127 C12.6661296,9.48143685 12.6661296,10.7163157 13.4277663,11.4779913 L13.5147218,11.5649468"
        id="Path"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M11.5651784,13.5118815 L11.4782229,13.424926 C10.7165473,12.6632893 9.48166848,12.6632893 8.7199929,13.424926 L4.85307969,17.2944478 C3.7156401,18.439034 3.7156401,20.2872066 4.85307969,21.4317928 L6.56697354,23.1456867 C7.71077373,24.2850306 9.56051831,24.2850306 10.7043185,23.1456867 L14.5703622,19.279643 C14.9365407,18.9140991 15.1423111,18.4179341 15.1423111,17.900528 C15.1423111,17.3831219 14.9365407,16.8869569 14.5703622,16.521413 L14.4834066,16.4344575"
        id="Path"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <line
        x1="10.2069328"
        y1="17.7927031"
        x2="17.7911957"
        y2="10.2067011"
        id="Path"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></line>
    </g>
  </svg>
);

export default Link;
