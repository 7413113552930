import React from 'react';
import type { IconProps } from '../types';

export const Tab4D = (props: IconProps) => (
  <svg
    width="22px"
    height="22px"
    viewBox="0 0 22 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>tab-4d</title>
    <g
      id="tab-4d"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Group"
        transform="translate(1, 1)"
        stroke="currentColor"
      >
        <rect
          id="Rectangle"
          x="0"
          y="0"
          width="20"
          height="20"
          rx="2"
        ></rect>
        <g
          id="Group-3"
          transform="translate(4, 5)"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path
            d="M6.9072464,0.398231009 C9.3565092,0.398231009 11.342029,2.38375081 11.342029,4.83301362 C11.342029,7.28227642 9.3565092,9.26779623 6.9072464,9.26779623 L6.9072464,0.398231009 Z"
            id="Path"
          ></path>
          <polyline
            id="Path-2"
            points="3.27497101 4.83301362 0 4.83301362 3.62401581 0 3.62401581 4.87475204 3.62401581 9.26779623"
          ></polyline>
        </g>
      </g>
    </g>
  </svg>
);

export default Tab4D;
