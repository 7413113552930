import React from 'react';
import type { IconProps } from '../types';

export const Patient = (props: IconProps) => (
  <svg
    width="28px"
    height="28px"
    viewBox="0 0 28 28"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>icon-patient</title>
    <g
      id="Production"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Artboard"
        transform="translate(-104, -235)"
      >
        <g
          id="icon-patient"
          transform="translate(104, 235)"
        >
          <rect
            id="Rectangle"
            x="0"
            y="0"
            width="28"
            height="28"
          ></rect>
          <circle
            id="Oval"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            cx="14"
            cy="14"
            r="9"
          ></circle>
          <path
            d="M7.7972,20.522 C9.06996592,19.9388093 10.371658,19.4209361 11.6972,18.9704 C12.4268,18.6992 12.308,16.7924 11.984,16.4372 C10.9423763,15.3083975 10.4320267,13.7886996 10.5812,12.26 C10.4872726,11.2868259 10.8022375,10.3184164 11.4506668,9.58668591 C12.0990961,8.8549554 13.0225934,8.42580227 14,8.402 C14.9774066,8.42580227 15.9009039,8.8549554 16.5493332,9.58668591 C17.1977625,10.3184164 17.5127274,11.2868259 17.4188,12.26 C17.5679733,13.7886996 17.0576237,15.3083975 16.016,16.4372 C15.692,16.7972 15.5732,18.6992 16.3028,18.9704 C17.628342,19.4209361 18.9300341,19.9388093 20.2028,20.522"
            id="Path"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default Patient;
