import React from 'react';
import type { IconProps } from '../types';

export const ViewportWindowLevel = (props: IconProps) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      id="Icons"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Artboard"
        transform="translate(-184, -87)"
      >
        <g
          id="viewport-window-level"
          transform="translate(184, 87)"
        >
          <rect
            id="Rectangle"
            opacity="0.5"
            x="0"
            y="0"
            width="24"
            height="24"
          ></rect>
          <g
            id="Group"
            transform="translate(4, 4)"
            fill="currentColor"
          >
            <g id="icon-info-link">
              <path
                d="M8,0 C3.581722,0 0,3.581722 0,8 C0,12.418278 3.581722,16 8,16 C12.418278,16 16,12.418278 16,8 C16,3.581722 12.418278,0 8,0 Z M8,1.01463415 C11.857911,1.01463415 14.9853659,4.14208897 14.9853659,8 C14.9853659,11.857911 11.857911,14.9853659 8,14.9853659 C4.14208897,14.9853659 1.01463415,11.857911 1.01463415,8 C1.01463415,4.14208897 4.14208897,1.01463415 8,1.01463415 Z"
                id="Oval"
              ></path>
            </g>
            <path
              d="M13.9802015,2.99802095 C15.2351212,4.37868604 16,6.21291722 16,8.2258274 C16,12.5193844 12.5200077,16 8.22721968,16 C6.21466999,16 4.38076731,15.2349842 3.00034948,13.9798397 C3.38388248,13.5964084 3.81184081,13.1683734 4.28334632,12.6967835 L4.86417397,12.1158518 C5.13143061,11.8485473 5.41083528,11.5690926 5.70238798,11.2774876 L7.59748054,9.38205562 C8.61791499,8.36143838 9.74768171,7.2314693 10.9867807,5.99214837 Z"
              id="Combined-Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ViewportWindowLevel;
