import React from 'react';
import type { IconProps } from '../types';

export const TabRoiThreshold = (props: IconProps) => (
  <svg
    width="23"
    height="22"
    viewBox="0 0 23 22"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="m13.34 11.74-2.971.425.425-2.971 7.64-7.64A1.8 1.8 0 1 1 20.98 4.1l-7.64 7.64zM8.644 19.034h7.383c.865 0 1.566-.701 1.566-1.566V10.03M12.504 4.94H5.066C4.2 4.94 3.5 5.643 3.5 6.508v1.972"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        stroke="currentColor"
        d="m11.5 8.5 2.333 2.333"
      />
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="m1 14 2.5-2.5L6 14M1 17.5 3.5 20 6 17.5" />
      </g>
      <path d="M0 0h22v22H0z" />
    </g>
  </svg>
);

export default TabRoiThreshold;
