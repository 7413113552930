import React from 'react';
import type { IconProps } from '../types';

export const Settings = (props: IconProps) => (
  <svg
    width="15.7826087px"
    height="15.7826087px"
    viewBox="0 0 15.7826087 15.7826087"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      id="Artboards"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Segmentation---assets"
        transform="translate(-42.127, -62.1087)"
        stroke="currentColor"
      >
        <g
          id="icon-display-settings"
          transform="translate(40, 60)"
        >
          <circle
            id="Oval"
            cx="10"
            cy="4.34782609"
            r="1.73913043"
          ></circle>
          <line
            x1="11.7373913"
            y1="4.34782609"
            x2="17.4095652"
            y2="4.34782609"
            id="Path"
          ></line>
          <line
            x1="2.62695652"
            y1="4.34782609"
            x2="8.2573913"
            y2="4.34782609"
            id="Path"
          ></line>
          <g
            id="Group-26"
            transform="translate(10.0183, 10) scale(-1, 1) translate(-10.0183, -10)translate(2.627, 8.2609)"
          >
            <circle
              id="Oval"
              transform="translate(3.8948, 1.7391) scale(-1, 1) translate(-3.8948, -1.7391)"
              cx="3.89478261"
              cy="1.73913043"
              r="1.73913043"
            ></circle>
            <line
              x1="5.63043478"
              y1="1.73913043"
              x2="14.7826087"
              y2="1.73913043"
              id="Path"
              transform="translate(10.2065, 1.7391) scale(-1, 1) translate(-10.2065, -1.7391)"
            ></line>
            <line
              x1="0"
              y1="1.73913043"
              x2="2.15217391"
              y2="1.73913043"
              id="Path"
              transform="translate(1.0761, 1.7391) scale(-1, 1) translate(-1.0761, -1.7391)"
            ></line>
          </g>
          <circle
            id="Oval"
            cx="8.26086957"
            cy="15.6521739"
            r="1.73913043"
          ></circle>
          <line
            x1="9.99652174"
            y1="15.6521739"
            x2="17.4095652"
            y2="15.6521739"
            id="Path"
          ></line>
          <line
            x1="2.62695652"
            y1="15.6521739"
            x2="6.52173913"
            y2="15.6521739"
            id="Path"
          ></line>
        </g>
      </g>
    </g>
  </svg>
);

export default Settings;
