import React from 'react';
import type { IconProps } from '../types';

export const MultiplePatients = (props: IconProps) => (
  <svg
    width="28px"
    height="28px"
    viewBox="0 0 28 28"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>icon-multiple-patients</title>
    <g
      id="4D-assets---final"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Artboard"
        transform="translate(-171, -280)"
      >
        <g
          id="icon-multiple-patients"
          transform="translate(171, 280)"
        >
          <rect
            id="Rectangle"
            x="0"
            y="0"
            width="28"
            height="28"
          ></rect>
          <g
            id="Group-3"
            transform="translate(2, 1.5)"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <g
              id="patient-icon"
              transform="translate(9, 10)"
            >
              <circle
                id="Oval"
                cx="7.5"
                cy="7.5"
                r="7.5"
              ></circle>
              <path
                d="M2.331,12.935 C3.39163827,12.4490078 4.47638164,12.0174468 5.581,11.642 C6.189,11.416 6.09,9.827 5.82,9.531 C4.95198021,8.59033128 4.52668888,7.3239163 4.651,6.05 C4.5727272,5.23902161 4.83519788,4.43201369 5.37555564,3.82223826 C5.9159134,3.21246283 6.68549454,2.85483523 7.5,2.835 C8.31450546,2.85483523 9.0840866,3.21246283 9.62444436,3.82223826 C10.1648021,4.43201369 10.4272728,5.23902161 10.349,6.05 C10.4733111,7.3239163 10.0480198,8.59033128 9.18,9.531 C8.91,9.831 8.811,11.416 9.419,11.642 C10.5236184,12.0174468 11.6083617,12.4490078 12.669,12.935"
                id="Path"
              ></path>
            </g>
            <g id="patient-icon">
              <path
                d="M7.5,0 C11.6421356,0 15,3.35786438 15,7.5 C15,8.48189915 14.8113103,9.41972745 14.4682129,10.2792028 C12.213795,10.9117859 10.3898771,12.5712406 9.53178711,14.7207972 C8.88630634,14.9028658 8.20449663,15 7.5,15 C3.35786438,15 0,11.6421356 0,7.5 C0,3.35786438 3.35786438,0 7.5,0 Z"
                id="Combined-Shape"
              ></path>
              <path
                d="M2.331,12.935 C3.39163827,12.4490078 4.47638164,12.0174468 5.581,11.642 C6.189,11.416 6.09,9.827 5.82,9.531 C4.95198021,8.59033128 4.52668888,7.3239163 4.651,6.05 C4.5727272,5.23902161 4.83519788,4.43201369 5.37555564,3.82223826 C5.9159134,3.21246283 6.68549454,2.85483523 7.5,2.835 C8.31450546,2.85483523 9.0840866,3.21246283 9.62444436,3.82223826 C10.1648021,4.43201369 10.4272728,5.23902161 10.349,6.05 C10.4733111,7.3239163 10.0480198,8.59033128 9.18,9.531 C8.91,9.831 8.811,11.416 9.419,11.642 C10.5236184,12.0174468 10.5236184,12.0174468 10.9701462,12.2764988"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default MultiplePatients;
